import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { BaseLayout, Button, ParagraphField, TextField } from '../components'

const formName = 'contact'

export default function Page() {
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [message, setMessage] = useState('')

  return (
    <>
      <Helmet htmlAttributes={{ lang: 'ja' }}>
        <title>お問い合わせ</title>
        <meta name="og:title" content="お問い合わせ" />
      </Helmet>
      <BaseLayout>
        <h1>お問い合わせ</h1>

        <p>
          ご質問やご意見がありましたら、こちらのお問い合わせフォームをご利用ください。なお、お問い合わせ時にいただくメールアドレスはメールでの返信のやりとりのみに使用します。詳細は<Link to="/privacy/">プライバシーポリシー</Link>をご覧ください。
        </p>

        <Form name={formName} method="POST" action="/contact-thank-you/" data-netlify="true">
          <input type="hidden" name="form-name" value={formName} />
          <Label>
            <FieldName>メールアドレス</FieldName>
            <TextField type="email" name="email" onChange={({ target: { value } }) => setEmail(value)} value={email} required />
          </Label>
          <Label>
            <FieldName>お名前</FieldName>
            <TextField type="text" name="name" onChange={({ target: { value } }) => setName(value)} value={name} required />
          </Label>
          <Label>
            <FieldName>メッセージ</FieldName>
            <ParagraphField name="message" rows={8} onChange={({ target: { value } }) => setMessage(value)} value={message} required />
          </Label>
          <Btn type="submit">送信</Btn>
        </Form>
      </BaseLayout>
    </>
  )
}

const Form = styled.form`
  display: block;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.14);
`

const Label = styled.label`
  display: block;

  & + & {
    margin-top: 1rem;
  }
`

const FieldName = styled.div`
  display: block;
  margin-bottom: 0.5rem;
`

const Btn = styled(Button)`
  margin-top: 1rem;
`
